<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <CasesHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p>我年近50，马上就到知天命的年纪，心思也多放在家庭和孩子上面。我学历不算高，一心希望儿子能受到好的教育，
          也想给妻子更好的生活。朋友们的孩子都陆续出国念书，我便也萌生出让儿子留学美国的想法。无奈孩子年纪尚小，不谙世事，妻子又百般不愿，
          唯恐孩子在外遭受欺凌。思来想去，不得其解，随后朋友建议我全家移民，一来方便妻子照顾孩子，其次方便儿子在美国读书、就业。</p>
        <p>通过多方比较，我们选择了跃迁出国。跃迁在移民领域深耕多年,对于移民政策和方案都聊熟于胸。
          跃迁的顾问详细解答了我所有的疑惑，推荐我移民美国。他们严谨的工作态度令我印象深刻。由于我的工作实在无法抽身赴美，所以跃迁顾问推荐可以让我妻子作为主申请人，
          带着孩子先过去，等以后合适的时候，我放下工作去美国跟他们汇合。在多方权衡下，我们选择了旧金山猎人角项目。我虽工作多年，挣得不少，
          然而50万美金的现金对我来说也是不小的压力。幸好跃迁出国的顾问们经验丰富，让我通过抵押房产贷款来获取资金，赠予妻子作为投资资金来源。
          随后跃迁文案顾问协助我们办理银行贷款，资金出境，准备I526申请文件。目前妻子和孩子已经在美国生活2年了，他们的I829申请也递交了。虽然我不能时刻陪伴孩子和妻子，但每次团聚之时，看到越来越懂事的孩子和越来越美丽的妻子，我真的很庆幸自己当初的决定，做了美国投资移民，选择了跃迁出国。感谢跃迁的顾问为我家庭的美国梦所做的所有努力。</p>
        <p>
          <img loading="lazy" class="aligncenter" src="@/assets/Cases/3/1.jpg">
        </p>
      </div>
      <CasesFooter class="mt-5" :index="info.index"/>
    </div>
  </div>

</template>

<script>
import CasesHeader from "@/components/Cases/CasesHeader";
import CasesFooter from "@/components/Cases/CasesFooter";

export default {
  name: "Cases5",
  components: {CasesFooter, CasesHeader},
  data() {
    return {
      info: this.$route.meta,
    }
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>